<template>
    <div class="o365-filter-cell" :data-o365-colindex="col.order" :style="{width: col.width+'px', left: col.left+'px'}"
        :o365-field="col.colId">
        <template v-if="col.filter">
            <component v-if="col.slots.filter" :is="col.slots.filter" :filterObject="filter.filterObject" :columnName="col.field" :column="col"></component>
            <component v-else :is="col.filter" :filterObject="filter.filterObject" :columnName="col.field" hideColumn class="bg-light-subtle border-0" hidePlaceholder></component>
        </template>
    </div>
</template>

<script lang="ts">
import type BaseColumn from 'o365.controls.Grid.BaseColumn.ts';

export interface IBaseHeaderCellProps {
    col: BaseColumn;
};
</script>

<script setup lang="ts">
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';

const props = defineProps<IBaseHeaderCellProps>();

const gridControl = useBaseGridControl();

const GridFilter = await gridControl.value.getExtension('filter');

const filter = GridFilter.extend(gridControl.value);
</script>